import React from 'react';
import styled from 'styled-components';
import SingleProject from './SingleProject';

export const ProjectGridStyles = styled.div`
  display: grid;
  @media (min-width: 768px) {
    grid-template-columns: repeat(3, 1fr);
  }
`;

export default function ProjectList({ projects, sortedCategory }) {
  return (
    <>
      <ProjectGridStyles>
        {projects.map((project) => (
          <SingleProject
            key={project.id}
            project={project}
            sortedCategory={sortedCategory}
          />
        ))}
      </ProjectGridStyles>
    </>
  );
}
