import React from 'react';
import styled from 'styled-components';

const VideoPlayerStyles = styled.div`
  position: relative;
  overflow: hidden;
  padding-top: 42.25%;
  max-width: 75%;
  margin: 0 auto;
  height: 100%;
  width: 100%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }

  @media (max-width: 767px) {
    max-width: none;
    padding-top: 56.25%;
  }
`;

export default function VideoPlayer({ vimeoId }) {
  return (
    <VideoPlayerStyles>
      <iframe
        title="vimeo-player"
        src={`https://player.vimeo.com/video/${vimeoId}`}
        frameBorder="0"
        allowFullScreen
      />
    </VideoPlayerStyles>
  );
}
