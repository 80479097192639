import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image';
import Modal from 'react-modal';
import VideoPlayer from './VideoPlayer';

export const ProjectStyles = styled.div`
  padding: 6.5% calc(6.5% - 1.2px) 0 6.5%;
  border-right: 1.2px solid var(--border-color);
  border-bottom: 1.2px solid var(--border-color);

  p {
    margin: 0;
    padding: 12px 0;
  }

  @media (min-width: 768px) {
    &:nth-of-type(-n + 3) {
      border-top: none;
    }

    &:nth-of-type(3n) {
      border-right: none;
    }
    &:nth-last-child(-n + 3) {
      border-bottom: none;
    }
  }

  @media (max-width: 767px) {
    padding: calc(6.5% / 3);
    border-right: none;

    &:last-of-type {
      border-bottom: none;
    }
  }

  @media (min-width: 2000px) {
    p {
      padding: 18px 0;
    }
  }
`;

export const ModalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0.75)',
    zIndex: '9999',
  },
  content: {
    width: '100%',
    maxwidth: '1142px',
    height: '100%',
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    background: 'none',
    border: 'none',
    display: 'flex',
    alignItems: 'center',
    overflowY: 'hidden',
  },
};

Modal.setAppElement('#___gatsby');

export default function Project({ project, sortedCategory }) {
  const [modalIsOpen, setIsOpen] = React.useState(false);
  let isActive = true;
  if (sortedCategory !== null) {
    isActive = project.categories.find(
      (category) => category.id === sortedCategory
    );
  }

  function openModal(e) {
    e.preventDefault();
    setIsOpen(true);
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <ProjectStyles>
        <a href="/" onClick={openModal}>
          <Img
            fluid={project.image.asset.fluid}
            alt={project.name}
            className={isActive ? 'active' : 'inactive'}
          />
        </a>
        <p>{project.name}</p>
      </ProjectStyles>
      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        style={ModalStyles}
        shouldCloseOnOverlayClick
        contentLabel="Video Modal"
      >
        <button className="btn" type="button" onClick={closeModal}>
          Close
        </button>
        <VideoPlayer vimeoId={project.vimeoId} />
      </Modal>
    </>
  );
}
