import { graphql, useStaticQuery } from 'gatsby';
import React from 'react';
import styled from 'styled-components';

const WrapperStyles = styled.div`
  height: 63px;
  width: 100%;
  border-bottom: 1.2px solid var(--border-color);
  display: flex;
  align-items: center;
  justify-content: center;

  @media (min-width: 2000px) {
    height: 99px;
  }
`;

const ListStyles = styled.ul`
  display: flex;

  li {
    margin: 0 2rem;
  }

  a {
    text-decoration: none;
  }

  @media (max-width: 400px) {
    width: 100%;
    justify-content: space-between;
    padding: 2rem 0;
    li {
      margin: 0 1rem;
    }
  }
`;

export default function CategoryFilter({ sortProjects }) {
  const data = useStaticQuery(graphql`
    query {
      categories: allSanityCategory {
        nodes {
          name
          order
          id
        }
      }
    }
  `);

  const categories = data.categories.nodes.sort(function (a, b) {
    return a.order - b.order;
  });

  return (
    <>
      <WrapperStyles id="category-filter">
        <ListStyles>
          <li key="all">
            <button
              type="button"
              className="btn-category"
              onClick={() => sortProjects(null)}
            >
              All
            </button>
          </li>
          {categories.map((category) => (
            <li key={category.id}>
              <button
                type="button"
                className="btn-category"
                onClick={() => sortProjects(category.id)}
              >
                {category.name}
              </button>
            </li>
          ))}
        </ListStyles>
      </WrapperStyles>
    </>
  );
}
