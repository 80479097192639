import { graphql } from "gatsby";
import React from "react";
import styled from "styled-components";
import CategoryFilter from "../components/CategoryFilter";
import ProjectList from "../components/ProjectList";
import VideoPlayer from "../components/VideoPlayer";
import SEO from "../components/SEO";

export const FeatureWrapperStyles = styled.div`
  border-bottom: 1.2px solid var(--border-color);
  padding: 52px 0;
  margin-top: 63px;

  @media (min-width: 2000px) {
    margin-top: 99px;
    padding: 78px 0;
  }

  @media (max-width: 767px) {
    padding: 2rem 1rem;
  }
`;

export default function HomePage({ data }) {
  const projects = data.projects.nodes.sort((a, b) => a.order - b.order);
  const featureVideo = data.general.featureVimeoId;
  const sortedProjects = [...projects];
  const [sortedCategory, setSortedCategory] = React.useState(null);

  const sortProjects = (categoryId) => {
    setSortedCategory(categoryId);
  };

  if (sortedCategory !== null) {
    sortedProjects.sort(function (a, b) {
      if (a.categories.find((category) => category.id === sortedCategory)) {
        if (b.categories.find((category) => category.id === sortedCategory)) {
          return 0;
        }
        return -1;
      }
      return 0;
    });
  }

  return (
    <>
      <SEO title="Home" />
      <main>
        <FeatureWrapperStyles>
          <VideoPlayer vimeoId={featureVideo} />
        </FeatureWrapperStyles>
        <CategoryFilter sortProjects={sortProjects} />
        <ProjectList
          projects={sortedProjects}
          sortedCategory={sortedCategory}
        />
      </main>
    </>
  );
}

export const query = graphql`
  query {
    projects: allSanityProject {
      nodes {
        name
        id
        vimeoId
        order
        categories {
          name
          id
        }
        image {
          asset {
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    general: sanityGeneral {
      featureVimeoId
    }
  }
`;
